.transfer-service-container {
  border: 1px solid #dbdfe3;
  border-radius: 4px;

  select {
    box-shadow: none;
  }

  select:focus {
    box-shadow: none !important;
  }
}

.transfer-service-picker {
  select {
    background-image: none !important;
    // font-weight: 500 !important;
    font-size: 13px !important;
    color: black !important;
  }

  select:invalid{
    color: gray !important;
  }
}