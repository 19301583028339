.minmax-container {
  border: 1px solid #dbdfe3;
  border-radius: 4px;
}


.minmax-picker {
  width: 100%;
  background-image: none !important;
  // font-weight: 500 !important;
  font-size: 13px !important;
  color: black !important;


  .minmax-picker-row {
    .minmax-picker-number-container {
      text-align: center;
      margin: auto;

      .minmax-picker-number {
        font-size: 15px;
        font-weight: 500;
        margin: auto;
        color: black;
      }
    }

    .minmax-picker-button-container {
      text-align: center;
      margin: auto;

      Button {
        height: 30px;
        width: 45px;
        background: none !important;
        box-shadow: none !important;
        border-radius: 100%;
        display: flex;
        margin: auto;
      }
    }
  }
}

