.footer {
  border-top: 1px solid #eee;
  margin: 2em 0.2em 0;

  .footer-row {
    margin: 1em 0 0.5em;

    .footer-button-container {
      text-align: right;

      Button {
        box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.2),
          -2px -2px 3px 0 rgba(255, 255, 255, 0.2);
        border-radius: 100px;

        span {
          text-align: center;
          margin: auto;
        }
      }
    }
  }
}
