.route-picker {
  border: 1px solid #dbdfe3;
  border-radius: 4px;

  select {
    box-shadow: none;
  }

  select:focus {
    box-shadow: none !important;
  }
}