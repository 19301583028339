
.dialog-content-modal {
  max-height: max-content !important;
}

.dialog-overlay-modal {
  overflow-y: auto !important;
}

.dialog-content-container-modal {
  overflow: visible !important;
}
