.tickets-picker {
  margin: 0.5em 0;

  .tickets-picker-row {
    .tickets-picker-number-container {
      text-align: center;
      margin: auto;

      .tickets-picker-number {
        font-size: 1.3em;
        margin: auto;
      }
    }

    .tickets-picker-button-container {
      text-align: center;
      margin: auto;

      Button {
        height: 2.6rem;
        width: 2.6rem;
        box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.2),
          -2px -2px 3px 0 rgba(255, 255, 255, 0.2);
        background-color: "white";
        border-radius: 100%;
        display: flex;
        margin: auto;
      }
    }
  }
}
