/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  margin: auto;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1em;
  padding: 0.4em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar #jump-to-today {
  cursor: pointer;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .cal-footer {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8em;
  padding: 0.4em 0;
  opacity: 0.6;
  border-top: 1px solid var(--border-color);
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 2.5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  transition: 0.5s ease-out;
}

.calendar .body .start {
  border-left: 1px solid transparent;
  border-image: linear-gradient(60deg, #234361 0%, rgba(14, 26, 38, 0.1) 70%);
  border-image-slice: 1;
  transition: 0.5s ease-in;
}

.calendar .body .end {
  border-right: 1px solid transparent;
  border-image: linear-gradient(60deg, rgba(14, 26, 38, 0.1) 30%, #234361 100%);
  border-image-slice: 1;
  transition: 0.5s ease-in;
}

.calendar .body .start.end {
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
  border-image: linear-gradient(
    60deg,
    rgba(14, 26, 38, 0.5) 10%,
    rgba(14, 26, 38, 0.1) 30%,
    rgba(14, 26, 38, 0.1) 70%,
    rgba(14, 26, 38, 0.5) 90%
  );
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 0.8em;
  line-height: 1;
  top: 0.4em;
  left: 0.4em;
  font-weight: 600;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  opacity: 0.3;
  font-size: 0.9em;
  position: absolute;
  bottom: -0.13em;
  right: -0.02em;
  transition: 1s ease-in;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  transition: 1s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}
