.availability-picker {
  padding-left: 0 !important;

  .availability-picker-container {
    justify-content: center;

    Button {
      height: 2rem;
      min-width: 5rem;
      padding: 0;

      box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.2), -2px -2px 3px 0 rgba(255, 255, 255, 0.2);
      border-radius: 10px;

      margin-top: 1em;
      text-align: center;

      span {
        font-size: 0.8rem;
        color: gray;
      }
    }

    Button.selected {
      span {
        color: white;
      }
    }
  }
}
