.product-card {
  border-radius: 5px;
  box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.2),
    -2px -2px 3px 0 rgba(255, 255, 255, 0.2);

  width: auto;
  height: 200px;
  position: relative;
  overflow: hidden;

  header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 90px 10px;
  }

  header > h2 {
    margin: 0;
    color: white;
    text-shadow: 0 1px 0 black;
  }

  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 7px 10px;

    h5,
    span {
      margin: 0;
      color: white;
    }
  }
}
